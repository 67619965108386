/* OurWork.css */
.our-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.our-heading hr {
  width: 10%;
  border: 2px solid black;
}

.work-content {
  color: black;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 1rem 0;
}

.content-box {
  /* background-image: linear-gradient(
    to right,
    #ff8177 0%,
    #ff867a 0%,
    #ff8c7f 21%,
    #f99185 52%,
    #cf556c 78%,
    #b12a5b 100%
  ); */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  color: black;
  border-radius: 4px;
  outline: none;
  border: none;
  height: 300px auto;
  padding: 100px 50px;
  cursor: pointer;
  transition: transform 0.4s ease, box-shadow 0.3s ease;
}

.content-box:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}
.content-box i {
  font-size: 4rem;
  color: black;
}

