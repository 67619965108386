* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8f7e7e;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #887272;
}
