.footer_container {
  padding: 2rem 0;
}
footer {
  padding-top: 20px;
  padding-bottom: 20px;
}

footer h5 {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

footer p {
  margin: 0;
}

footer .bi {
  color: #f8f9fa;
}

.footer_content {
  display: flex;
  justify-content: center;
}

@media (max-width: 791px) {
  .footer_content {
    align-items: center;
    flex-direction: column;
  }
  .address {
    text-align: center;
  }
}

@media (max-width: 500px) {
  .footer_content {
    align-items: center;
  }
}
