/* Landing Container */
.landing-container {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: background-image 1.5s ease-in-out;
}

/* Content Styling */
.content {
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Header Styling */
.content h1 {
  font-size: 3.5rem;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

/* Paragraph Styling */
.content p {
  font-size: 1.7rem;
  letter-spacing: 1px;
  color: #fff;
}

.landing_btn {
  padding: 15px 20px;
  width: 20%;
  border-radius: 4px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px;
  border: none;
  outline: none;
  cursor: pointer;
  background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
  transition: 0.8s all ease-in-out;
}

.landing_btn:hover {
  background-image: linear-gradient(to top, #ff0844 0%, #ffb199 100%);
}

/* Media Queries for Responsiveness */

/* For large screens (desktops) */
@media (min-width: 1200px) {
  .content {
    width: 100%;
  }

  .content h1 {
    font-size: 4rem;
  }

  .content p {
    font-size: 1.8rem;
  }
}

/* For medium screens (tablets) */
@media (max-width: 991px) {
  .content {
    width: 100%;
  }

  .content h1 {
    font-size: 3rem;
  }

  .content p {
    font-size: 1.5rem;
  }

  .landing_btn {
    width: 50%;
  }
}

/* For small screens (phones) */
@media (max-width: 767px) {
  .content {
    width: 100%;
    padding: 15px;
  }

  .content h1 {
    font-size: 2.5rem;
  }

  .content p {
    font-size: 1.3rem;
  }

  .landing_btn {
    width: 80%;
  }
}

/* For extra small screens (phones in portrait mode) */
@media (max-width: 576px) {
  .content h1 {
    font-size: 2rem;
  }

  .content p {
    font-size: 1.2rem;
  }

  .landing-container {
    height: 90vh;
  }

  .landing_btn {
    width: 100%;
  }
}